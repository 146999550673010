.item-termos {
    font-size: 1.2rem;
    font-weight: 400;
    color: #625b4f;
    margin-bottom: 1rem;
    line-height: 1.5rem;
    margin-top: 1rem;
}

.item-termos h5 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    line-height: 1.5rem;
    margin-top: 1rem;
    padding-right: 2rem;
    padding-left: 1rem;
    color: #625b4f;
}

.lista-pagamentos li {
    margin-bottom: 1rem;
}

.lista-pagamentos {
    list-style: decimal;
    margin-left: 2rem;
}

.lista-reembolso {
    margin-left: 2rem;
}
