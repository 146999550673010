.email-link {
    color: #000;
    text-decoration: underline;
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0 0.5rem;
    transition: color 0.3s;
    /* make it warp */
    word-wrap: break-word;
}

.phone-link {
    color: #000;
    text-decoration: underline;
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0 0.5rem;
    transition: color 0.3s;
}
