.containerdrip {
    position: relative;
    max-width: 960px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-top: 40px;

    /*
    width: 100%;
    display: flex;
    justify-content: center;
    
    padding-right: 0 !important; 
    padding-left: 0 !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
    */
}

.rowdrip {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
@media screen and (max-width: 687px) {
    .rowdrip {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100% !important;
    }
    .search {
        right: 0px !important;
    }
    .event-card-details {
        display: flex;
        justify-content: center;
    }
    .center-title {
        display: flex;
        justify-content: center;
    }
}

@media screen and (max-width: 1000px) {
    .rowdrip {
        justify-content: center;
        align-items: center;
        width: 90%;
    }
}

.event-card {
}

.position {
    float: left;
}

@media only screen and (min-width: 48em) {
    .position {
        /*
        margin-left: 12.5%;
        flex-basis: 37.5%;
        max-width: 37.5%;
        */
    }
}

.event-card-details {
    width: 100%;
    font-size: 12px;
    line-height: 12px;
    color: #7a848b;
    font-weight: 600;
}

/*
@media only screen and (min-width: 747px){
    .search {
        padding-top: 35px;
        padding-left: 300px;
    }
}*/

.search {
    /*display: inline-flex;*/
}

@media only screen and (min-width: 1000px) {
    .search {
        padding-top: 35px;
        position: relative;
        right: -300px;
    }
}

@media only screen and (max-width: 1000px) {
    .search {
        padding-top: 35px;
        position: relative;
        right: -100px;
    }
}

@media only screen and (max-width: 500px) {
    .search {
        right: 0px;
        display: flex;
        justify-content: center;
    }
}

.startnumber {
    width: 100%;
    box-shadow: 0 0 5px rgb(0 0 0 / 20%);
    border-radius: 4px;
    overflow: hidden;
    background-color: #f6f8f9;
}

.search-label {
    height: 50px;
    width: 100%;
    font-size: 12px;
    color: #74848b;
    text-transform: uppercase;
    text-align: center;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
}

.search-textfield > div:first-of-type {
    text-align: center;
    color: #0e1921;
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
}

input {
    overflow: visible;
    text-align: center;
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
    outline: none;

    width: 345px;
    height: 108px;
    font-family: "alte DIN", sans-serif;
    font-size: 72px;

    background-color: #f6f8f9;
}

.search-textfield > div:first-of-type {
    height: 108px;
    text-align: center;
    background-color: #f6f8f9;
    font-family: "alte DIN", sans-serif;
    font-size: 72px;
    line-height: 108px;
}

.search-button {
    justify-content: center;
    align-items: center;
}

.startnumber-search-textfield.input-edit-icon input {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0iIzdBODQ4QiI+CiAgPHBhdGggZD0iTTcuMTI3IDIyLjU2MkwwIDI0bDEuNDM4LTcuMTI4IDUuNjg5IDUuNjl6bTEuNDE0LTEuNDE0TDE5Ljc2OSA5LjkyM2wtNS42OS01LjY5MkwyLjg1MiAxNS40NThsNS42ODkgNS42OXpNMTguMzA5IDBsLTIuODE2IDIuODE3IDUuNjkxIDUuNjkxTDI0IDUuNjg5IDE4LjMwOSAweiIvPgo8L3N2Zz4=);
    background-repeat: no-repeat;
    background-position: 95% 50%;
    -webkit-background-size: 12px 12px;
    background-size: 12px;
}

/*
hr { background-color: red; height: 1px; border: 0; border-color: red }*/
.linha {
    /*
    width: 50%;
    margin: 0 auto;
    border-top: 2px solid #d4dce2;
    background-color: red;
    color: #d4dce2;
    border-color: #d4dce2;
    margin-top: 120px; 
    margin-bottom: 60px;*/
    width: 50%;
    margin: 0 auto;
    margin-top: 80px;
    margin-bottom: 35px;

    border: 1px solid #d4dce2;
    border-top-width: 1px;
}

/*body {
    background-image: url("https://picsum.photos/seed/picsum/200/300");
    background-color: rgba(255,255,255,0.95);
}*/
