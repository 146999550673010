.linha_dois {
    /*
    width: 50%;
    margin: 0 auto;
    border-top: 2px solid #d4dce2;
    background-color: red;
    color: #d4dce2;
    border-color: #d4dce2;
    margin-top: 120px; 
    margin-bottom: 60px;*/
    width: 50%;
    margin-top: 60px;

    /*border: 1px solid #d4dce2; */
    border-top-width: 1px;
}
