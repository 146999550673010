.padding-well {
    padding: 19px;
}
.fundo-cinza {
    background-color: #e8e8e8;
}

.top-margin {
    margin-top: 80px;
}

.bot-margin {
    margin-bottom: 10px;
}

@media screen and (max-width: 575px) {
    /*
    .top-margin {
        margin-top: 0px;
    }*/
    .roww {
        display: flex;
        flex-direction: column;
    }
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.roww {
    margin-right: -15px;
    margin-left: -15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.tamano_h4 {
    font-size: 18px;
}

.black_background {
    background-color: #222222;
}

.black-text {
    color: #8a8a8a;
}

.informacao a:hover {
    text-decoration: underline;
}

.informacao {
    display: flex;
    flex-direction: column;
}

.payments {
}

.payment-methods {
    display: flex;
    align-items: center;
}

.payment-methods img {
    width: 70px;
    height: 50px;
    margin-right: 10px;
}

.contact {
    display: flex;
    flex-direction: column;
}
.phone-icon {
    margin-bottom: 15px;
}
.socials {
    margin-top: 15px;
}
.social-icon svg {
    width: 70px;
    height: 50px;
    font-size: 2m;
}

@media screen and (max-width: 765px) {
    .informacao {
        margin-bottom: 30px;
    }
    .contact {
        margin-bottom: 30px;
    }
    .copyright {
        display: flex;
        justify-content: center;
    }
}

@media screen and (max-width: 575px) {
    .black_background {
        padding: 30px;
    }
    .payments {
        margin-bottom: 30px;
    }
}

@media screen and (min-width: 767px) {
    .footer-container {
        position: absolute;
        bottom: 0;
        width: 100%;
    }
}
/* on hover .livro-reclamacoes put color a bit lighter  */

.livro-reclamacoes {
    padding-top: 10px;
}

.livro-reclamacoes a {
    display: block;
    outline: none;
    background: url("https://publicitario.pt/application/files/2915/3687/9718/livro-reclamacoes-170x70-w.png")
        no-repeat 0 0;
    /* background-repeat: no-repeat; */
    width: 170px;
    height: 70px;
    background-size: 100% auto;
    overflow: hidden;
}
.livro-reclamacoes a:hover {
    /* change to image livro-reclamacoes-on.png */
    background-image: url("https://publicitario.pt/application/files/4215/3687/9743/livro-reclamacoes-170x70.png");
}
.livro-reclamacoes span {
    display: none;
}
