.head-text {
   position: relative;
   /*display: block;
   box-sizing: border-box;*/

}
.head-image {
    /*
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;*/
}
.text-on-image {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 40%;
  transform: translate(-50%, -50%);
  color: white;
}


.main-page-container {
    
    min-height: 453px;
    max-width: 1200px; 
    margin: auto;
}

@media (min-width: 1024px) {
    .head-image{
        /* center image without flex and without resizing */
        //display: block;
        //margin: auto;
        //width: 100%;
        //height: 100%;
        margin: auto;
        width: 50%

    }
}


.social {
    /*resize the images to 30x30 */



    display: flex;
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 30px;

}


.facebook {
    padding: 5px;
}


.instagram {
    padding: 5px;
}


