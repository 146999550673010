.App {
    font-family: sans-serif;
    text-align: center;
}
.overlay {
    position: fixed;
    width: 100%;
    height: 100%;

    background-color: black;
    opacity: 0.7;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
    inset: 0;
    z-index: 1;
}
