.privacidade {
    margin-left: 1rem;
    margin-right: 1rem;
}

.privacidade h5 {
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.compras {
    margin-right: 1rem;
}
.lista-dados {
    margin-left: 2rem;
    list-style: circle;
}
